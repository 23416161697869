import { UAParser } from "ua-parser-js";

import App from "~/App.svelte";

_REMOVABLE_BUILD_: {
  // PWAのinjectManifest戦略＋カスタムService Workerを使用している場合に開発モードで動作しない問題の対策
  // https://vite-pwa-org.netlify.app/guide/development.html#injectmanifest-strategy
  navigator.serviceWorker.register("/dev-sw.js?dev-sw", {
    type: "module",
  });
  break _REMOVABLE_BUILD_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
}

// polyfill for Object.fromEntries()
if (!Object.fromEntries) {
  Object.fromEntries = function (entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error(
        "Object.fromEntries() requires a single iterable argument",
      );
    }
    const obj = {};
    for (const [key, value] of entries) {
      obj[key] = value;
    }
    return obj;
  };
}

// polyfill for Object.hasOwn()
if (!("hasOwn" in Object)) {
  // @ts-ignore
  Object.hasOwn = Object.call.bind(Object.hasOwnProperty);
}

let app;
let moveToUnsupportedBrowser = false;

if (!import.meta.env.DEV) {
  const parsedUA = UAParser(navigator.userAgent);
  if (
    (parsedUA.os?.name === "iOS" && !window.webkit?.messageHandlers) ||
    (parsedUA.os?.name === "Android" && parsedUA.browser.name !== "Chrome")
  ) {
    window.location.href = "/unsupported-browser.html";
    moveToUnsupportedBrowser = true;
  }
}

if (!moveToUnsupportedBrowser) {
  app = new App({
    target: document.getElementById("app"),
  });
}

export default app ?? {};
